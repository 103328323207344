package Structs

import DataBase.UINKDBInterface
import Utils.WrappedName
import Utils.fixJson
import Utils.getJsonWithConfig
import Utils.roundToDecimals
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*
import myName
import printPlat


@myName("ProductDelivery")
@Serializable
data class ProductDelivery(
    @SerialName("id") var productId: Int,
    @SerialName("v") var value: Float = 0f,
    @SerialName("r") var returns: Float = 0f,
    @SerialName("p") var price: Float,
    @SerialName("sup") var supplier_id: Int = -1,
    @SerialName("u") var use_price: Byte = 0, // 0 dont use , 1 use - no tax product, 2 use - not included,3-use included
    @SerialName("utn") var use_price_tax_note: Byte = 0, // 0 dont use , 1 use - no tax product, 2 use - not included,3-use included
    @SerialName("d") var discount: Float = 0f,


    @SerialName("wv")
    //this is for orders, second amount
    var wrapped_amount: Float = 0f,

    @SerialName("wr")
    //this is for orders, second amount
    var wrapped_amount_return: Float = 0f,

    // conversion_ratio between the second amount to the actual value 1 value * conversion_ratio = wrapped_amount
    @SerialName("cr") var conversion_ratio: Float = 0f,
    // conversion_ratio between the second amount to the actual value 1 value * conversion_ratio = wrapped_amount
    @SerialName("wu") var wrapped_unit: Int = 0,
    @SerialName("n") var notes: String? = null,
    @SerialName("n2") var notes2: String? = null,
    @SerialName("n3") var notes3: String? = null,
    @SerialName("s") var doneState: Byte = 0,
    @SerialName("cost") var cost: Float? = null,
    @SerialName("o") var tempPosition: Int = 0,
    @SerialName("mek") var nmekulkal: Int = 0,

    ) {
    var isClient: Boolean = true

    var useWrapped: Byte = 0
    var changedPrice: Byte = 1
    var priceBefore: Float? = null
    var priceAfter: Float? = null

    @myName("getUnit")
    fun getUnit(): WrappedName {
        var x: WrappedName? = null
        if ((wrapped_amount > 0 || wrapped_amount_return > 0) && wrapped_unit == 0) x =
            getProduct().getOrderProduct()?.extra_unit?.let { WrappedName.NONE.convert(it) }

        return x ?: WrappedName.NONE.convert(wrapped_unit)
    }

    @myName("getProduct")
    fun getProduct(): Product {
        return if (isClient) UINKDBInterface.activeDB.getClientProduct(productId).first!! else UINKDBInterface.activeDB.getSupplierProduct(
            productId
        ).first!!
    }


    @myName("getOrderProduct")
    fun getOrderProduct(): OrderProduct? {
        return if (isClient) UINKDBInterface.activeDB.getOrderProduct(productId)?.first() else null
    }

    @myName("getSupplier")
    fun getSupplier(): Supplier? {
        return if (supplier_id != -1) UINKDBInterface.activeDB.getSupplier(supplier_id).first else null
    }

    @myName("discountCalculated")
    fun discountCalculated(): Float {
        return (1 - discount / 100)
    }

    @myName("usePrice")
    fun usePrice(note_used: Byte): Boolean {
        return use_price > 0 || (note_used == (1).toByte() && use_price_tax_note > 0)
    }

    @myName("isNoTax")
    fun isNoTax(note_used: Byte): Boolean {
        return (use_price == (1).toByte()) || (note_used == (1).toByte() && use_price_tax_note == (1).toByte())
    }

    @myName("taxNotIncluded")
    fun taxNotIncluded(note_used: Byte = 0): Boolean {
        return use_price == (2).toByte() || (note_used == (1).toByte() && use_price_tax_note == (2).toByte())
    }

    @myName("taxIncluded")
    fun taxIncluded(note_used: Byte = 0): Boolean {
        return use_price == (3).toByte() || (note_used == (1).toByte() && use_price_tax_note == (3).toByte())
    }

    @myName("empty")
    fun empty(): Boolean {
        var cond = value == 0f
        var cond2 = returns == 0f
        var cond3 = conversion_ratio == 0f || wrapped_amount == 0f
        var cond4 = conversion_ratio == 0f || wrapped_amount_return == 0f
        return cond && cond2 && cond3 && cond4

    }

    @myName("emptyOrder")
    fun emptyOrder(): Boolean {
        var cond = value == 0f
        var cond2 = wrapped_amount == 0f
        return cond && cond2

    }

    @myName("doCopy")
    fun doCopy(id: Int? = null): ProductDelivery {
        val d = copy(productId = id ?: productId)
        d.isClient = isClient
        d.changedPrice = changedPrice
        return d
    }

    @myName("negative")
    fun negative(): ProductDelivery {
        return copy(
            value = returns,
            returns = value,
            wrapped_amount = wrapped_amount_return,
            wrapped_amount_return = wrapped_amount
        )
    }

    fun sql(): String {
        return if (!empty()) "${productId}_s#${value}@${productId}_r#${returns}@"
        else ""
    }

    @myName("isCollected")
    fun isCollected(): Boolean {
        return doneState == (1).toByte()

    }

    fun compare(pd: ProductDelivery): Boolean {
        return pd.productId == productId && pd.value == value && pd.returns == returns && pd.price == price && pd.use_price == use_price && pd.discount == discount && pd.wrapped_amount == wrapped_amount && pd.conversion_ratio == conversion_ratio
    }

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["id"] = JsonPrimitive(productId.toString())
        map["v"] = JsonPrimitive(value.toString())
        map["r"] = JsonPrimitive(returns.toString())
        map["p"] = JsonPrimitive(price.toString())
        if (use_price > 0) {
            map["u"] = JsonPrimitive(use_price.toString())
        }
        if (use_price_tax_note > 0) {
            map["utn"] = JsonPrimitive(use_price_tax_note.toString())
        }
        if (discount != 0f) {
            map["d"] = JsonPrimitive(discount.toString())
        }
        if (supplier_id != -1) map["sup"] = JsonPrimitive(supplier_id.toString())
        if (wrapped_amount != 0f) map["wv"] = JsonPrimitive(wrapped_amount.toString())
        if (conversion_ratio != 0f) map["cr"] = JsonPrimitive(conversion_ratio.toString())
        if (wrapped_amount_return != 0f) map["wr"] = JsonPrimitive(wrapped_amount_return.toString())
        if (wrapped_unit != 0) map["wu"] = JsonPrimitive(wrapped_unit.toString())
        if (doneState != (0).toByte()) map["s"] = JsonPrimitive(doneState.toString())
        if (notes != null) {
            map["n"] = JsonPrimitive(notes!!.replace("\"", "").replace("\'", ""))
        }
        if (notes2 != null) {
            map["n2"] = JsonPrimitive(notes2!!.replace("\"", "").replace("\'", ""))
        }
        if (notes3 != null) {
            map["n3"] = JsonPrimitive(notes3!!.replace("\"", "").replace("\'", ""))
        }
        if (tempPosition != 0) {
            map["o"] = JsonPrimitive(tempPosition.toString())
        }
        if (nmekulkal==1){

            map["mek"] = JsonPrimitive(nmekulkal.toString())
        }
        return JsonObject(map)
    }


    companion object {
        @myName("createFromJson")
        fun createFromJson(js: String): List<ProductDelivery> {
            return (getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray).map {
                getJsonWithConfig().decodeFromJsonElement(serializer(), it)
            }
        }

        @myName("createUsedState")
        fun createUsedState(
            noTaxProduct: Int, noTaxClient: Int, pricesShowIncluded: Int, forceInclude: Boolean = false
        ): Byte {
            return when {
                noTaxProduct == 1 || noTaxClient == 1 -> {
                    1
                }

                pricesShowIncluded == 0 && !forceInclude -> {
                    2
                }

                pricesShowIncluded == 1 || forceInclude -> {
                    3
                }

                else -> {
                    0
                }

            }
        }

        fun compareProductDvList(
            pd1L: List<ProductDelivery>,
            pd2L: List<ProductDelivery>,
        ): Boolean {
            if (pd1L.size != pd2L.size) return false
            pd2L.forEach { pd2 ->
                if (pd1L.find { it.compare(pd2) } == null) return false
            }

            return true
        }

        fun toJsonArrayString(pd: List<ProductDelivery>): String {
            return if (pd.isEmpty()) {
                "[]"
            } else {
                val payload = pd.map {
                    Json.encodeToJsonElement(JsonObject.serializer(), it.toJson()).toString()
                }.joinToString(",")
                return "[$payload]"
            }

        }

        fun weightEstimate(pds: List<ProductDelivery>): Float {
            return pds.sumByDouble {
                (it.getOrderProduct()?.weight ?: 0f).toDouble() * (it.value + it.conversion_ratio * it.wrapped_amount)
            }.toFloat()
        }

        fun kartonEstimate(pds: List<ProductDelivery>): Float {

            return roundToDecimals(pds.sumByDouble { pd ->
                (pd.getOrderProduct()?.volume?.let { if (it == 0f) 0f else (pd.value + pd.conversion_ratio * pd.wrapped_amount) / it }
                    ?: 0f).toDouble()
            }.toFloat(), 2)
        }

        fun toWrappedAmountDescribe(pds: List<ProductDelivery>): List<ProductDelivery> {
            val dvs: MutableList<ProductDelivery> = mutableListOf()
            pds.forEach {
                if (it.value > 0 || it.returns > 0) {
                    dvs.add(it)
                }
                if ((it.wrapped_amount > 0 || it.wrapped_amount_return > 0) && it.conversion_ratio > 0) {
                    val cppd = it.doCopy()
                    cppd.value = it.wrapped_amount
                    cppd.returns = it.wrapped_amount_return
                    cppd.useWrapped = 1
                    cppd.wrapped_unit = it.wrapped_unit
                    dvs.add(
                        cppd
                    )
                }
            }
            return dvs
        }
    }
}


