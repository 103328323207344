package Structs

import DataBase.UINKDBInterface
import Utils.DocType
import Utils.OrderStatus
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import myName

@myName("Note")
@Serializable
sealed class Note(

) : InformationBase() {
    abstract val date: String
    abstract val date_issued: String
    abstract val agent: String
    abstract val id: Int
    abstract val ent_id: Int
    abstract var active: Int
    abstract var delivery_value: String
    abstract var connected_id: Long
    abstract var notes: String
    abstract var agent_id: Int
    abstract var notes2: String
    abstract var notes3: String
    abstract var notes4: String
    abstract var notes5: String
    abstract var history: String
    abstract var action_time: String
    abstract var driver_id: Int?
    var value: Float = 0f
    var taxPaid: Float = 0f
    var taxPaidPos: Float = 0f
    var taxPaidNeg: Float = 0f
    var amount_pos: Float = 0f
    var amount_pos_sec: Float = 0f
    var amount_neg: Float = 0f
    var amount_neg_sec: Float = 0f
    var value_pos: Float = 0f
    var value_neg: Float = 0f
    var discount: Float = 0f
    var discountTax: Float = 0f

    var delivery_info: MutableList<ProductDelivery> = mutableListOf()
    var histroy_info: MutableList<ProductDelivery> = mutableListOf()

    override fun getAmounts(): Float {
        return delivery_info.sumByDouble { (it.value + (it.wrapped_amount) * it.conversion_ratio).toDouble() }
            .toFloat()
    }
    override fun getReturnAmounts(): Float {
        return delivery_info.sumByDouble { (it.returns + (it.conversion_ratio) * it.wrapped_amount_return).toDouble() }
            .toFloat()
    }

    override fun getConnectedDate(): String {
        return date
    }

    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(ent_id).first?.getName() ?: ""
    }

    override fun getConnectedAgent(): String {
        return agent
    }
    override fun getComments(): String {
        return notes
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedDateTime(): String {
        return date_issued
    }

    override fun getConnectedValue(): Float {
        return value
    }

    override fun isActive(): Boolean {
        return active == 1
    }

    override fun isUsed(): Boolean {
        return false
    }

    override fun toString(): String {
        return date.toString()
    }

    override fun getConnectedEntId(): Int {
        return ent_id
    }

    open fun getEnt(client: Boolean = true): Entity {
        return EmptyEnt()
    }

    override fun getConnectedTaxValueReal(): Float {
        return taxPaid
    }

    override fun getBeforeValueReal(): Float {
        return return value - taxPaid
    }

    @myName("isOnlyReturns")
    fun isOnlyReturns(): Boolean {
        return delivery_info.all { it.value == 0f && it.wrapped_amount == 0f && (it.returns > 0 || it.wrapped_amount_return > 0) }
    }

    @myName("hasExtraUnit")
    fun hasExtraUnit(): Boolean {
        return delivery_info.any { it.wrapped_amount > 0f || it.wrapped_amount_return > 0 }
    }

    @myName("getAgentId")
    fun getAgentId(): Int? {
        return UINKDBInterface.activeDB.getAgentBy(name = agent, id = null, force = true).first?.id
    }

    @myName("hasReturns")
    fun hasReturns(): Boolean {
        return delivery_info.any { (it.returns > 0 || it.wrapped_amount_return > 0) }
    }

    @myName("hasValues")
    fun hasValues(): Boolean {
        return delivery_info.any { (it.value > 0 || it.wrapped_amount > 0) }
    }

    override fun getConnectedProductsIds(): List<Int> {
        return delivery_info.map { it.productId }
    }

    enum class NoteType(val size: Int) {
        VALUES(0),
        RETURNS(1),
        BOTH(2);

        fun convert(size: Int): NoteType {
            return when (size) {
                0 -> VALUES
                1 -> RETURNS
                else -> BOTH
            }
        }
    }


    companion object {
        fun sql(delivery_info: List<ProductDelivery>): String {
            var s: StringBuilder = StringBuilder()
            for (element in delivery_info) {
                s.append(element.sql())
            }
            return s.toString()
        }

        fun realSizeSplitReturns(l: List<Note>): Int {
            return l.sumBy { it.splittedSize() }
        }
        fun getAllProducts(l: List<Note>): Set<Int> {
            val v = mutableSetOf<Int>()
            l.forEach {
                v.addAll(it.delivery_info.map { it.productId })
            }
            return v
        }
        fun splitListToValueRet(l: List<Note>): Pair<List<Note>, List<Note>> {
            val v = mutableListOf<Note>()
            val r = mutableListOf<Note>()

            l.forEach {
                val type = NoteType.BOTH.convert(it.splittedSize())
                when (type) {

                    NoteType.VALUES -> v.add(it)
                    NoteType.RETURNS -> r.add(it)
                    else -> {
                        v.add(it)
                        r.add(it)
                    }
                }

            }
            return Pair(v, r)
        }

    }

    fun splittedSize(): Int {
        return (if (delivery_info.any { it.value + it.conversion_ratio * it.wrapped_amount > 0 }) 1 else 0) +
                (if (delivery_info.any { it.returns + it.conversion_ratio * it.wrapped_amount_return > 0 }) 1 else 0)
    }

}

@myName("DeliveryNote")
@Serializable
class DeliveryNote(
    override val date: String,
    override val date_issued: String,
    override val agent: String,
    val delivery_id: Int,
    override val ent_id: Int,
    override var active: Int,
    override var delivery_value: String,
    val paid: Int = 0,
    val paid_date: String? = null,
    override var connected_id: Long = -1,
    val order_id: Int = -1,
    override var notes: String = "",
    val pdf: Int = 0,

    var signer: String? = null,
    var digi_sign: Int? = null,
    var digi_photo: Int? = null,
    var date_sign: String? = null,
    override var driver_id: Int? = null,
    override var agent_id: Int = -1,
    override var notes2: String="",
    override var notes3: String="", override var history: String="[]", override var action_time: String="",
    override var notes4: String="",
    override var notes5: String="",
) : Note() {
    var isSupNote: Boolean = false

    init {
        delivery_info = ProductDelivery.createFromJson(delivery_value).toMutableList()
        histroy_info = ProductDelivery.createFromJson(history).toMutableList()
    }

    override val id: Int
        get() = delivery_id

    override fun getConnectedDocType(): Int {
        return if (isSupNote) DocType.SUP_DELIVERY_NOTES.state else DocType.DELIVERY_NOTES.state
    }

    fun getConnectedRealDocType(): Int {
        return if (isSupNote) DocType.SUP_DELIVERY_NOTES.state else
            (
                if (isOnlyReturns()) {
            DocType.RETURNS.state
        } else {
            DocType.DELIVERY_NOTES.state
        })
    }

    override fun getEnt(client: Boolean): Entity {
        return if (client)
            UINKDBInterface.activeDB.getClient(ent_id).first!!
        else
            UINKDBInterface.activeDB.getSupplier(ent_id).first!!
    }

    override fun signData(): NoteSignHolder? = if (digi_sign == 1 || digi_photo == 1) NoteSignHolder(
        id,
        4,
        digi_sign ?: 0,
        digi_photo ?: 0,
        signer ?: "",
        date_sign ?: ""
    ) else UINKDBInterface.activeDB.getSignOffline(delivery_id).first

    override fun digiSign(): Boolean =
        if (digi_sign == 1) true else UINKDBInterface.activeDB.getSignOffline(delivery_id).first?.digi_sign == 1

    override fun digiPhoto(): Boolean =
        if (digi_photo == 1) true else UINKDBInterface.activeDB.getSignOffline(delivery_id).first?.digi_photo == 1

    override fun isUsed(): Boolean {
        return paid != 0
    }

    fun setSign(NoteSignHolder: NoteSignHolder) {
        digi_sign = NoteSignHolder.digi_sign
        digi_photo = NoteSignHolder.digi_photo
        signer = NoteSignHolder.signer
        date_sign = NoteSignHolder.date
    }

    @myName("getDriver")
    fun getDriver(): Agent? {
        return driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(it, null, driver = true).first
        }
    }
    @myName("getConnectedOrder")
    fun getConnectedOrder(): OrderNote? {
        return if (order_id != null)
            UINKDBInterface.activeDB.getClientOrderNotes(order_id = order_id).first.firstOrNull()
        else null
    }

    @myName("getCollectedKarton")
    fun getCollectedKarton(): Map<Int,CollectionObject>? {
        return getConnectedOrder()?.collectionObject
    }
}

@myName("OrderNote")
@Serializable
class OrderNote(
    override val date: String,
    override val date_issued: String,
    override val agent: String,
    val order_id: Int,
    override val ent_id: Int,
    override var active: Int,
    override var delivery_value: String,
    val sup_delivery_info: String,
    override var connected_id: Long = -1,
    override var notes: String = "",
    val category: String = "",
    override var driver_id: Int? = null,
    var signer: String? = null,
    var digi_sign: Int? = null,
    var digi_photo: Int? = null,
    var date_sign: String? = null,
    val ref_id: String? = null,
    override var agent_id: Int = -1,
    override var notes2: String="",
    override var notes3: String="",
    override var action_time: String="",
    override var history: String,
    val collection:String="{}",
    val freeze:String="[]",
    override var notes4: String="",
    override var notes5: String="",
    val close_note_id:Int?=null,
    val static_data:String?=null,

) : Note() {
    var freeze_info: MutableList<ProductDelivery> = mutableListOf()
    var ClientStaticData :ClientStaticData?=null
    val sup_delivery_value: MutableList<ProductDelivery> =

        ProductDelivery.createFromJson(sup_delivery_info).toMutableList()

    @myName("orderStatus")
    var orderStatus: OrderStatus = OrderStatus.REGULAR.convert(active)
    val collectionObject = CollectionObject.createFromJson(collection)
    init {
        delivery_info = ProductDelivery.createFromJson(delivery_value).toMutableList()
        histroy_info = ProductDelivery.createFromJson(history).toMutableList()
        if(static_data!="null" && !static_data.isNullOrEmpty()){
            ClientStaticData= Structs.ClientStaticData.createFromJson(static_data)
        }

        freeze_info = if(freeze=="null") mutableListOf() else  ProductDelivery.createFromJson(freeze).toMutableList()
    }

    override fun getConnectedDocType(): Int {
        return DocType.ORDERS.state
    }

    override fun isActive(): Boolean {
        return active >= 1
    }

    override val id: Int
        get() = order_id

    override fun isUsed(): Boolean {
        return orderStatus == OrderStatus.CLOSED || orderStatus==OrderStatus.ARRIVED
    }

    @myName("getActiveProducts")
    fun getActiveProducts(date: String? = null): List<ProductDelivery> {
        return delivery_info.filter {
            if (date != null) {
                UINKDBInterface.activeDB.getClient(ent_id).first?.getPrice(it.productId)?.get(date)?.first != 0f
            } else {
                true
            }

        }
    }

    override fun getEnt(client: Boolean): Entity {
        return UINKDBInterface.activeDB.getClient(ent_id).first!!
    }

    override fun signData(): NoteSignHolder? = if (digi_sign == 1 || digi_photo == 1) NoteSignHolder(
        id,
        10,
        digi_sign ?: 0,
        digi_photo ?: 0,
        signer ?: "",
        date_sign ?: ""
    ) else UINKDBInterface.activeDB.getSignOffline(id, 10).first

    override fun digiSign(): Boolean =
        if (digi_sign == 1) true else UINKDBInterface.activeDB.getSignOffline(id, 10).first?.digi_sign == 1

    override fun digiPhoto(): Boolean =
        if (digi_photo == 1) true else UINKDBInterface.activeDB.getSignOffline(id, 10).first?.digi_photo == 1

    @myName("getDriver")
    fun getDriver(): Agent? {
        return driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(it, null, driver = true).first
        }
    }

    @myName("getKartonString")
    fun getKartonString():String{
        return collectionObject.map {
            val collectorName = UINKDBInterface.activeDB.getAgentBy(it.key, null, collector = true).first?.user_name ?: it.key.toString()
            "$collectorName - ${it.value.box}"
        }.joinToString(", ")
    }
    @myName("isCollected")
    fun isCollected(productId:Int):Boolean{
        UINKDBInterface.activeDB.getClientProduct(productId).first?.let {
            if(collectionObject.containsKey(it.getOrderProduct()?.collector))
                return true
        }
        return false
    }
    @myName("getKartonLst")
    fun getKartonLst():List<CollectionObject>{
        return collectionObject.values.toList()
    }

    fun setSign(NoteSignHolder: NoteSignHolder) {
        digi_sign = NoteSignHolder.digi_sign
        digi_photo = NoteSignHolder.digi_photo
        signer = NoteSignHolder.signer
        date_sign = NoteSignHolder.date
    }
}
data class baseChangeHolder(val c_id:Int, val p_id:Int, val amount:Float, val day:Int?=null)