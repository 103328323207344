package Structs

import myName

@myName("CalculationHolder")
class CalculationHolder(
    val product: Product? = null,
    var price: Float = 0f,
    var price_min: Float = Float.MAX_VALUE,
    var price_max: Float = Float.MIN_VALUE,
    var positiveInstances: Float = 0f,
    var positiveValue: Float = 0f,
    var negativeInstances: Float = 0f,
    var negativeValue: Float = 0f,
    var totalValue: Float = 0f,
    var taxPaid: Float = 0f,
    var discount: Float = 0f,
    var priceBefore: Float = 0f,
    var price_min_before: Float = Float.MAX_VALUE,
    var price_max_before: Float = Float.MIN_VALUE,
    priceDistinct:Boolean = false
) {
    var calcHolders: MutableMap<Float,CalculationHolder>? = null
    init {
        if(priceDistinct){
            calcHolders = mutableMapOf()
        }

    }
    fun merge(other: CalculationHolder): CalculationHolder {
        val calc = CalculationHolder(
            product = product,
            price = price,
            price_min = price_min,
            price_max = price_max,
            positiveInstances = positiveInstances,
            positiveValue = positiveValue,
            negativeInstances = negativeInstances,
            negativeValue = negativeValue,
            totalValue = totalValue,
            taxPaid = taxPaid,
            discount = discount
        )
        calc.price += other.price
        calc.price_min = if (calc.price_min < other.price_min) calc.price_min else other.price_min
        calc.price_max = if (calc.price_max > other.price_max) calc.price_max else other.price_max
        calc.positiveInstances += other.positiveInstances
        calc.positiveValue += other.positiveValue
        calc.negativeInstances += other.negativeInstances
        calc.negativeValue += other.negativeValue
        calc.totalValue += other.totalValue
        calc.taxPaid += other.taxPaid
        calc.discount += other.discount
        return calc

    }

}